import React, { useCallback, useEffect, useMemo, useState } from "react"

import { connectRefinementList } from "react-instantsearch-dom"
import {
  FilterHeader,
  FilterHeaderTitle,
  FilterItem,
  FilterItemChild,
  FilterList,
  FilterSectionCounter,
  Label,
  LabelGroup,
  Section,
  Title,
} from "./MentorSearchFilters.styled"
import { Icon } from "../Icon/Icon"
import { useAnalytics } from "../../hooks/useAnalytics"
import { useApp } from "../../hooks/useApp"

export const MentorSearchFilterSection = ({ title, attribute }: { title: string; attribute: string }) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [filterCounter, setFilterCounter] = useState(true)

  return (
    <>
      <Section>
        <FilterHeader onClick={() => setIsCollapsed(!isCollapsed)}>
          <FilterHeaderTitle>
            <Title>{title}</Title>
            {filterCounter ? <FilterSectionCounter>({filterCounter})</FilterSectionCounter> : ""}
          </FilterHeaderTitle>
          {isCollapsed ? <Icon name={`chevronDown`} size={`s`} colour={`grey`} /> : <Icon name={`chevronUp`} size={`s`} colour={`grey`} />}
        </FilterHeader>
        <FilterList isCollapsed={isCollapsed}>
          <CustomFilter showMore showMoreLimit={200} attribute={attribute} setFilterCounter={setFilterCounter} />
        </FilterList>
      </Section>
    </>
  )
}

const Filter = ({ items, refine, currentRefinement, setFilterCounter, attribute }) => {
  const { member } = useApp()
  const { trackCustomEvent } = useAnalytics()

  const sortedItems = useMemo(
    () =>
      items
        .filter(i => (attribute === "countryAndCity" ? i.label?.trim()?.length > 0 : true))
        .filter(i => i.count > 1)
        .sort((a, b) => a.label.localeCompare(b.label)),
    [items, attribute]
  )

  const groupedLocations = useMemo(() => {
    if (attribute !== "countryAndCity") {
      return null
    }

    let grouped = {}

    sortedItems.forEach(item => {
      let [country] = item.label.split("|")

      if (!grouped[country]) {
        grouped[country] = []
      }

      grouped[country].push(item)
    })

    return grouped
  }, [attribute, sortedItems])

  const handleFilterClick = useCallback((value) => {
    for (const label of value) {
      if (items?.some((item: any) => item.label === label && !item.isRefined)) {
        trackCustomEvent("skill_search", {
          skill_name: label,
          member_id: member?._id || "",
          organisation_name: member?.company || "",
        })
      }
    }

    refine(value)
  }, [items])

  useEffect(() => {
    setFilterCounter(currentRefinement.length)
  }, [currentRefinement.length])

  if (groupedLocations) {
    return Object.entries(groupedLocations).map(([country, cities]: any) => (
      <>
        <LabelGroup>{country}</LabelGroup>
        {cities.map(item => (
          <FilterItemChild>
            <FilterItem
              onClick={event => {
                event.stopPropagation()
                handleFilterClick(item.value)
              }}
            >
              {item.isRefined ? <Icon name={"checked"} size={"m"} colour={"secondary"} /> : <Icon name={"unchecked"} size={"m"} colour={"grey"} />}

              <Label style={{ fontWeight: item.isRefined ? "bold" : "" }}>{item.label?.split("|")?.[1] || item.label || ""}</Label>
            </FilterItem>
          </FilterItemChild>
        ))}
      </>
    ))
  }

  return (
    <>
      {sortedItems.map(item => (
        <FilterItem
          onClick={event => {
            event.stopPropagation()
            handleFilterClick(item.value)
          }}
        >
          {item.isRefined ? <Icon name={"checked"} size={"m"} colour={"secondary"} /> : <Icon name={"unchecked"} size={"m"} colour={"grey"} />}

          <Label style={{ fontWeight: item.isRefined ? "bold" : "" }}>{item.label}</Label>
        </FilterItem>
      ))}
    </>
  )
}

const CustomFilter = connectRefinementList(Filter)
